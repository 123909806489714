import React from "react"
import Layout from "../chunks/layout"
import { Helmet } from "react-helmet"
import Main from "../components/main"

export default function PrivacyPolicy({ location }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout | Boostik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Main title="Privacy Policy" viewName="privacy" />
      <div className="p-10" style={{
        maxWidth: "80%",
        margin: "auto",
        textAlign: "justify"
      }}>
        <h2 style={{ fontSize: "18px", textAlign: "center", paddingBottom: "30px" }}>Privacy policy</h2>
        <p>
          Boostik (and its owner) then inform about their privacy policy, applied to personal data. In this way, all
          the personal data that you have under your responsibility will be treated in accordance with Law on the
          Protection of Personal Data, and the due security and confidentiality measures will be kept. Visiting the
          boostik.io website does not imply that the user must provide any information regarding their identity, and
          it cannot be associated with a specific user by the company. In the event that personal data is provided,
          these data will be collected and used in accordance with the limitations and rights contained in the
          aforementioned data protection rule. Users who provide personal data clearly, accurately and unequivocally
          consent to these conditions.
        </p>
        <p>
          <b>1.</b> DATA OF THE DATA CONTROLLER OwnerName: Boostik. Email for data protection communications:
          info@boostik.io .
        </p>
        <p>
          <b>1.1.</b> Applicable regulations Our Privacy Policy has been designed in accordance with the General Data
          Protection Regulation of the EU 2016/679 of the European Parliament and of the Council, of April 27, 2016,
          regarding the protection of natural persons in what regarding the processing of personal data and the free
          circulation of these data and by which Directive 95/46 / CE (General Data Protection Regulation), and Organic
          Law 3/2018 of December 5, on the Protection of Personal Data and Guarantee of Digital Rights. By providing us
          with your data, you declare that you have read and are aware of this Privacy Policy, giving your unequivocal
          and express consent to the processing of your personal data according to the purposes and terms expressed
          herein. The Company may modify this Privacy Policy to adapt it to new legislation, jurisprudence or
          interpretation of the Spanish Agency for Data Protection. These privacy conditions may be supplemented by the
          Legal Notice, Cookies Policy and the General Conditions that, where appropriate, are collected for certain
          products or services, if such access involves any specialty in the protection of personal data.
        </p>
        <p><b>1.2.</b> Delegate of Data Protection Boostik. The contact email: info@boostik.io.</p>
        <p><b>2.</b> PURPOSE OF THE PROCESSING OF PERSONAL DATA The treatment that we carry out of your personal data
          responds
          to the following purposes: - To provide you with information related to the products and services offered by
          our company and which are detailed on this web site. - Carry out the contracting of our services by accepting
          the corresponding budget / order and / or signing a commercial contract. - Send you news and updates about our
          entity by email and / or post, as well as updates to our catalog of products and services.</p>
        <p><b>2.1.</b> Period of Conservation of your data We will keep your personal data from the moment you give us
          your
          consent until you revoke it or request the limitation of the treatment. In such cases, we will keep your data
          blocked for the legally required periods.</p>
        <p><b>3.</b> LEGITIMATION AND DATA COLLECTED The legitimacy for the processing of your data is the express
          consent granted by means of a positive and affirmative act (fill in the corresponding form and check the
          acceptance box of this policy) at the time you provide us with your personal data.
        </p>
        <p><b>3.1.</b> Consent to process your data By filling in the forms, ticking the box "I accept the Privacy
          Policy" and clicking to send the data, or by sending emails to the Company through the accounts enabled for
          this purpose, the User states that they have read and expressly accepted this privacy policy, and grants your
          unequivocal and express consent to the processing of your personal data according to the indicated purposes.
        </p>
        <p><b>3.2.</b> Data categories The data collected refers to the category of identifying data, such as: Name and
          Surname, Telephone, Postal Address, Company, Email, as well as the IP address from where you access the data
          collection form.</p>
        <p><b>4.</b> SECURITY MEASURES Within our commitment to guarantee the security and confidentiality of your
          personal data, we inform you that the necessary technical and organizational measures have been adopted to
          guarantee the security of personal data and avoid its alteration , loss, treatment or unauthorized access,
          taking into account the state of the technology, the nature of the stored data and the risks to which they are
          exposed, according to Art. 32 of the RGPD EU 679/2016.</p>
        <p><b>5.</b> TRANSFER OF DATA No data transfers or international transfers of your data are foreseen, except for
          those authorized by tax, commercial and telecommunications legislation, as well as in those cases in which a
          judicial authority requires it.</p>
        <p><b>6.</b> USER RIGHTS Any interested party has the right to obtain confirmation about whether or not we are
          treating personal data that concerns them. Interested persons have the right to access their personal data, as
          well as to request the rectification of inaccurate data or, where appropriate, request its deletion when,
          among other reasons, the data is no longer necessary for the purposes for which it was collected. In certain
          circumstances, the interested parties may request the limitation of the processing of their data, in which
          case we will only keep them for the exercise or defense of claims. For reasons related to their particular
          situation, the interested parties may object to the processing of their data. The person in charge of the file
          will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of
          possible claims. In accordance with current legislation, you have the following rights: the right to request
          access to your personal data, the right to request its rectification or deletion, the right to request the
          limitation of its treatment, the right to oppose the treatment, the right to the portability of the data. data
          and likewise, to revoke the consent granted. Likewise, he has the right to file a claim with the Spanish Data
          Protection Agency.</p>
        <p><b>6.1.</b> How to exercise my rights? To exercise your rights, you must contact the person in charge,
          requesting the corresponding form to exercise the chosen right. Optionally, you can go to the competent
          Control Authority to obtain additional information about your rights. The contact information for the exercise
          of your rights is the email:info@boostik.io . Remember to attach a copy of a document that allows us to
          identify you. 7. CONSENT TO SEND ELECTRONIC COMMUNICATIONS Likewise, and in accordance with the provisions of
          Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce, completing the data
          collection form and By checking the corresponding "I accept" box, you are granting express consent to send
          information about the Company to your email address, telephone, fax or other electronic means.</p>

        <h2 style={{ fontSize: "18px", textAlign: "center", paddingBottom: "30px", paddingTop: '50px' }}>Cookies policy</h2>
        <h3>1. INTRODUCTION</h3>
        <p>In accordance with article 22.2 of Law 34/2002, drawn up by section five of the second final provision of Law
          9/2014, of May 9, General Telecommunications ("BOE" 10 May; Correction of errors "BOE" May 17) and effective
          from May 11, 2014: “Service providers may use data storage and retrieval devices (so-called cookies) on
          recipients' terminal equipment, provided that they have given their consent after They have been provided with
          clear and complete information on its use, in particular, on the purposes of data processing, in accordance
          with the provisions of Organic Law 3/2018 on the protection of personal data. " However, and in accordance
          with the third paragraph of article 22.2 “The foregoing shall not prevent the possible storage or access of a
          technical nature for the sole purpose of carrying out the transmission of a communication over an electronic
          communications network or, insofar as it is strictly necessary , for the provision of an information society
          service expressly requested by the recipient ”. This last point refers to the so-called technical cookies.</p>
        <p>The procedure to comply with the regulatory obligations regarding cookies is:</p>
        <p>a) Make an inventory of the cookies used by the website, compiling the name of the cookie, its entity (own or
          third parties), its purpose and the conservation period. To carry out this task, you can use the developer
          tools that include web browsers or go to the company that designed and programmed the web page.</p>
        <p>b) Define the text of the purposes in a summarized way and that will be included in the text of the so-called
          “first layer”. The installation of this first layer requires computer and programming knowledge or the use of
          third-party tools, always bearing in mind that the user must be given the possibility of rejecting the
          installation of cookies, except for technical cookies that cannot be rejected.</p>
        <p>c) Place a link to a web page with the cookie policy (which is called the second layer). This page must be
          accessible from any page that makes up the website.</p>
        <h3>2.- HOW TO INFORM AND OBTAIN INFORMED CONSENT</h3>
        <p>In its guidelines on transparency, WG29 recommends the use of layered privacy statements or notices, that is,
          containing the information in layers, so that the user is allowed to go to those aspects of the statement or
          notice that are relevant. greater interest for him, thus avoiding information fatigue, and this without
          prejudice to the fact that all the information is available in a single place or in a complete document that
          can be easily accessed if the interested party wishes to consult it in its entirety.</p>
        <p>This system consists of displaying the essential information in a first layer together with 3 buttons,
          ACCEPT, REJECT (only technical cookies are authorized) and MORE INFORMATION. This first layer is manifested
          for practical purposes by showing a banner or a flap, when the page or web application is accessed, and then
          it is complemented by a second layer, which is materialized in a page in which more detailed and specific
          information is offered. about cookies (commonly called the cookie policy page).</p>
        <p>In the first layer, which for greater clarity can be identified under a commonly used term (such as
          "cookies"), the following information would be included:</p>
        <p>a) Identification of the editor responsible for the website. The company name will not be necessary, as long
          as your complete identification data appears in other sections of the website (legal notice, privacy policy,
          etc.) and your identity can be clearly deduced from the website itself (for example, when the website itself
          domain corresponds to the name of the publisher or the brand with which it is identified in front of the
          public or said name or brand clearly appears on the website).</p>
        <p>b) Identification of the purposes of the cookies that will be used.</p>
        <p>c) Information on whether the cookies are own (of the person responsible for the website) or also of third
          parties associated with him, without it being necessary to identify the third parties in this first layer.
        </p>
        <p>d) Generic information on the type of data that will be collected and used in the event that user profiles
          are drawn up (for example, when behavioral advertising cookies are used).</p>
        <p>e) Way in which the user can accept, configure and reject the use of cookies.
        </p>
        <p>f) A clearly visible link directed to a second information layer in which more detailed information is
          included, using, for example, the term "Cookies", "Cookie Policy" or "More information, click here".</p>
        <p>This information will be provided before the use of cookies, including, where appropriate, their
          installation, to</p>
        <p>This information will be provided before the use of cookies, including, where appropriate, their
          installation, through a format that is visible to the user and that must be kept until the user performs the
          action required to obtain consent or their rejection (meanwhile, technical cookies may be installed on the
          user's computer, eg: PHPSESSID, ASPNET_SESSION, and the like).</p>
      </div>
    </Layout>
  )
}
